<div class="app-container">
  <header *ngIf="showHeader"
          class="ahe-ui-navbar navbar learning-dark app-header">
    <div class="header-wrapper">
      <h2 class="navbar-heading">Assess Authoring</h2>
      <aa-program-banks [selectedBank]="getSelectedBank()">
      </aa-program-banks>
      <div class="float-end">
        <aa-app-menu></aa-app-menu>
        <aa-user-profile></aa-user-profile>
      </div>
    </div>
  </header>
  <router-outlet></router-outlet>
  <aa-loading-spinner></aa-loading-spinner>
</div>
