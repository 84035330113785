import { Observable, of } from 'rxjs';
import { Config, GetAllGlobalTogglesAction } from '@mhe/ol-platform/global-toggles';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { APP_INITIALIZER, FactoryProvider } from '@angular/core';

export const globalTogglesConfigFactory = (): Observable<Config> => (
  of({ sdlcEnvironment: environment.envName } as Config)
);

export const initApplication = (store: Store) => (
  () => store.dispatch(new GetAllGlobalTogglesAction())
);

export const appInitProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: initApplication,
  multi: true,
  deps: [Store],
};
