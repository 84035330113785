export const environment = {
  production: false,
  envName: 'qastg',
  baseUrl: 'https://my-qastg.mheducation.com',
  apiBaseUrl: 'https://authoring-api-qastg.assess.nonprod.mheducation.com/v1',
  apiBaseUrlV2: 'https://authoring-api-qastg.assess.nonprod.mheducation.com/v2',
  wirisUrl: 'https://wiris-tinymce-qastg.assess.nonprod.mheducation.com/7.5.0.1392',
  wirisMathUrl: 'https://wiris-qastg.assess.nonprod.mheducation.com/',
  mmsUrl: 'https://mms-api-qastg.assess.nonprod.mheducation.com',
  bentoUrl: 'https://qastg.metadata-api.nonprod.mheducation.com',
  assessAuthUrl: 'https://lfoajei71i.execute-api.us-east-1.amazonaws.com/qastg',
  assessApiUrl: 'https://authoring-api-qastg.assess.nonprod.mheducation.com/v1',
  assessBaseUrl: 'https://authoring-api-qastg.assess.nonprod.mheducation.com/v1',
  standardsUrl: 'https://standards-api-qastg.assess.nonprod.mheducation.com',
  tinymceUrl: 'https://dle-cdn.mheducation.com/mhe/tinymce/6.8.2',
  /* TODO (eslint): Naming convention */
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  QTIurl: 'https://air-exchange-api-qastg.assess.nonprod.mheducation.com',
  newRelicAsset: 'assets/newrelic/newrelic-nonprod.js',
  assessDeliveryBaseUrl: 'https://assess-apiv2-qastg.assess.nonprod.mheducation.com',
  assessBaseUrlv2: 'https://assess-apiv2-qastg.assess.nonprod.mheducation.com/v2',
  airExchangeUrl: 'https://air-exchange-api-qastg.assess.nonprod.mheducation.com',
  enableEntityMenu: true,
  externalContentSubscriptions: {
    rubricSubscriptionXid: 'urn:com.mheducation.openlearning:integration.internal.lars:qastg.us-east-1:lars_subscription:555ba023-98e8-49e2-a72e-295a42eeeaa7',
    courseResourcesSubscriptionXid: 'urn:com.mheducation.openlearning:integration.internal.lars:qastg.us-east-1:lars_subscription:da5ce60c-04db-49bc-ba8b-289976c88192'
  }
};
