import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as loadExternalScript from 'little-loader';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import cdnAssets from 'cdn-assets';

if (environment.production) {
  enableProdMode();
}

/* eslint-disable @typescript-eslint/dot-notation */
Promise.all([
  Object.values(cdnAssets).map((url) =>
    new Promise((resolve, reject) => {
      loadExternalScript(url, (err) => (err ? reject(err) : resolve(url)));
    }),
  ),
  Promise.resolve(!window['Zone'] ? import('zone.js/dist/zone') : null),
]).then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { preserveWhitespaces: true })
    .catch((err) => console.log(err));
});

// TODO: Set the environment through airConfig slice in the ngrx/store
// Set the environment variable for AIR. Short term solution
window['airEnv'] = environment.envName;
window['_wrs_conf_setSize'] = true;
window['_wrs_conf_wirisPluginPerformance'] = false;
window['_wrs_conf_wirisEditorSaveMode'] = 'image';
window['_wrs_conf_imageClassName'] = 'Wirisformula';
window['_wrs_conf_saveMode'] = 'xml';
window[
  '_wrs_int_conf_file'
] = `${environment.wirisUrl}/api/integration/configurationjs.php`;
