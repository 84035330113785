import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { environment } from '../environments/environment';
import {
  AssessState,
  ConfigService,
  LoadingSpinnerService,
  ProgramBanksService,
  RouterListenerService,
  loadingSpinnerActions,
} from '@mhe/dle-assess-authoring-ui-submodules';

@Component({
  selector: 'aa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [RouterListenerService],
})
export class AppComponent implements AfterViewInit {
  showHeader: boolean;
  constructor(
    routerListenerService: RouterListenerService,
    private router: Router,
    private programBanksService: ProgramBanksService,
    private store: Store<AssessState>,
    private loadingSpinnerService: LoadingSpinnerService,
    private configService: ConfigService
  ) {
    routerListenerService.startListening();

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        if (
          event.url === '/' ||
          event.url === '/login' ||
          event.url.split('?')[0] === '/login'
        ) {
          this.loadingSpinnerService.show();
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
      });

    this.configService.setConfig(environment);
  }

  ngAfterViewInit() {
    // this.store.dispatch(new loadingSpinnerActions.InitiateLoadingSpinnerAction());
  }

  getSelectedBank() {
    return this.programBanksService.getSelectedProgram();
  }
}
